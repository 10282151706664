import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ViewComponentComponent } from './components/view-component/view-component.component';
import { LoginComponent } from './pages/auth/login/login.component';
import { RegistroComponent } from './pages/auth/registro/registro.component';
import { CursosOnlineOpenComponent } from './pages/capacitaciones/cursos-online-open/cursos-online-open.component';
import { CursosOnlineComponent } from './pages/capacitaciones/cursos-online/cursos-online.component';
import { FacebookLiveOpenComponent } from './pages/capacitaciones/facebook-live-open/facebook-live-open.component';
import { FacebookLiveComponent } from './pages/capacitaciones/facebook-live/facebook-live.component';
import { ClientComponent } from './pages/client/client.component';
import { ConstructorComponent } from './pages/home/constructor/constructor.component';
import { PreHomeComponent } from './pages/home/pre-home/pre-home.component';
import { ModulosComponent } from './pages/programa-profesional/modulos/modulos.component';
import { ProgramaProfesionalComponent } from './pages/programa-profesional/programa-profesional.component';
import { VideosGraduadosComponent } from './pages/graduados/videos-graduados/videos-graduados.component';
import { VideosGraduadosOpenComponent } from './pages/graduados/videos-graduados-open/videos-graduados-open.component';

import { PublicComponent } from './pages/public/public.component';
import { HerramientasComponent } from './pages/soluciones/herramientas/herramientas.component';
import { InternaComponent } from './pages/soluciones/interna/interna.component';
import { VideoTipsComponent } from './pages/soluciones/video-tips/video-tips.component';
import { PerfilComponent } from './pages/user/perfil/perfil.component';
import { AuthorizationGuard } from './guards/authorization.guard';

import { ReclamationComponent } from './components/reclamation/reclamation.component';

/* Update malcolm  */
import { ViewElementComponent } from './components/view-element/view-element.component';
import { ErrorComponent } from './pages/error/error.component';
import { PlanosComponent } from './pages/soluciones/planos/planos.component';
import { ResetPassComponent } from './pages/auth/reset-password/reset-password.component';
/* Fin Update malcolm */

const routes: Routes = [
  { path: '', component: PreHomeComponent },
  { path: 'constructor', component: ConstructorComponent },
  { path: 'constructor/herramientas-obras-construccion',  component: HerramientasComponent },
  { path: 'constructor/herramientas/calculadora-materiales-construccion', component: InternaComponent },
  { path: 'constructor/herramientas/planos-para-casas', component: PlanosComponent }, //planos
  { path: 'constructor/herramientas/planos/:pdfid', component: ViewElementComponent }, //VER PDF
  { path: 'constructor/herramientas/manual-de-construccion', component: ViewElementComponent, data: { pdf: 'manual-de-construccion' } }, //VER PDF
  { path: 'constructor/herramientas/manual-de-construccion-casas', component: ViewElementComponent, data: { pdf: 'manual-de-construccion-casas' } }, //VER PDF

  { path: 'constructor/herramientas/tabla-de-equivalencias', component: ViewElementComponent, data: { pdf: 'tabla-de-equivalencias' } }, //VER PDF
  { path: 'constructor/herramientas/tabla-equivalencias-construccion', component: ViewElementComponent, data: { pdf: 'tabla-equivalencias-construccion' } }, //VER PDF

  { path: 'constructor/herramientas/manual-de-manejo', component: ViewElementComponent, data: { pdf: 'manual-de-manejo' } }, //VER PDF
  { path: 'constructor/herramientas/manual-de-manejo-construccion', component: ViewElementComponent, data: { pdf: 'manual-de-manejo-construccion' } }, //VER PDF

  { path: 'constructor/herramientas/curso-evolucion-tendencias', component: ViewElementComponent, data: { pdf: 'curso-evolucion-tendencias' } }, //VER PDF
  { path: 'constructor/herramientas/curso-tendencias-construccion-peru', component: ViewElementComponent, data: { pdf: 'curso-tendencias-construccion-peru' } }, //VER PDF

  { path: 'constructor/cursos-gratuitos', component: CursosOnlineComponent },

  { path: 'constructor/libro-de-reclamaciones', component: ReclamationComponent },

  { path: 'usuario', component: ClientComponent },
  { path: 'usuario/cursos-online-open', component: CursosOnlineOpenComponent },
  { path: 'usuario/facebook-live', component: FacebookLiveComponent },
  { path: 'usuario/facebook-live-open', component: FacebookLiveOpenComponent },
  { path: 'usuario/video-tips', component: VideoTipsComponent },
  { path: 'usuario/cursos-view/:token/:cursoid', component: ViewComponentComponent },
  { path: 'usuario/programa', component: ProgramaProfesionalComponent },
  { path: 'usuario/programa-modulos', component: ModulosComponent },
  { path: 'usuario/graduados', component: VideosGraduadosComponent },
  { path: 'usuario/graduados-open/:videoid', component: VideosGraduadosOpenComponent },
  { path: 'usuario/perfil', component: PerfilComponent, canActivate: [AuthorizationGuard], canLoad: [AuthorizationGuard] },
  { path: 'constructor/reset-password', component: ResetPassComponent},
  { path: '404', component: ErrorComponent },
  //{ path: '**', redirectTo: '' },

];

@NgModule({
  imports: [RouterModule.forRoot( routes, { enableTracing: false, useHash: false } )],

  exports: [RouterModule],
})
export class AppRoutingModule {}
