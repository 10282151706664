import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cursosServices } from '@app/components/lista-cursos/cursos.service';
import { IUsuario, IUsuarioU } from '@app/models/IUsuario';
import { CurrentService } from '@app/services/current.service';
import { SwitchService } from '@app/services/switch.service';
import { UsuarioService } from '@app/services/usuario.service';
import axios from 'axios';
import Swal from 'sweetalert2';
import { environment } from '../../../../environments/environment';

@HostListener('window:scroll', [])
@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.scss'],
})
export class PerfilComponent implements OnInit {
  paso: number = 1;
  nombre: string = '';
  apellido: string = '';
  profesion: string = '';
  email: string = '';
  celular: string = '';
  telefono: string = '';
  cursosarray: any = [];
  //cursosarray2: any = ['61eb1d51c0de2', '6208554a2a65b', '6209b96842557', '6209c6b88e3f7', '6209cbaebe125', '620a3c69e4f31']
  //cursosarray: any = [['620a3c69e4f31',0], ['6209cbaebe125',1], ['6209c6b88e3f7',2], ['6209b96842557',3], ['6208554a2a65b',4], ['61eb1d51c0de2',5]]
  usuario: IUsuario[] = [];
  editUsuario: IUsuarioU = {
    nombre: '',
    apellidos: '',
    ciudad: '',
    email: '',
    celular: '',
    telefono: '',
    password: '',
  };
  cursoRecomendado: any = [];
  // Porcertante de cursos
  token: any = localStorage.getItem('token') || null;
  totalcursos: any = [];
  certificados: any = [];
  cursosPorcentaje: any = [];
  imagenes: Array<string> = [
    '../../../assets/img/cursosv2/1.png',
    '../../../assets/img/cursosv2/2.png',
    '../../../assets/img/cursosv2/3.png',
    '../../../assets/img/cursosv2/4.png',
    '../../../assets/img/cursosv2/5.png',
    '../../../assets/img/cursosv2/6.png',
  ];

  constructor(
    private usuarioService: UsuarioService,
    private cursos: cursosServices,
    private router: Router,
    private switchService: SwitchService
  ) {}

  ngOnInit(): void {
    //var img = 0;
    this.listarUsuario();
    this.scrollToTop();
    this.mostrarAvanzes();
    this.listCurso();
    this.listCertificados();
  }

  async mostrarAvanzes() {
    //const payloads = new FormData();
    //console.log("Mostrar")
    /* payloads.append('id', '100044178321934');
    axios
      .post( `https://www.facebook.com/api/graphql/`, payloads )
      .then((res) => {
        console.log('resres', res);
      })
      .catch((err) => {
        console.log(err + 'asd');
      }); */

    //const payload2 = new FormData();
    //payload2.append('token', this.token);
    const headers = {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Authorization' : this.token
    };
    await axios
      .post(`${environment.api_url}user/lessons`, '', { headers: headers } )
      .then((res) => {
        //console.log('lessionesss2', res);
        res.data.data.forEach((curso: any) => {
          this.cursosarray.push(curso.idCurso);
        });
      });

    for (var i = 0; i < this.cursosarray.length; i++) {
      const payload = new FormData();
      //console.log("Mostrar")
      axios
        .post(`${environment.api_url}user/videos_by_course`, { 'idCurso' : this.cursosarray[i] }, { headers: headers } )
        .then((res) => {
          //console.log('RES FINAL', res);

          //console.log('IMAGEN', res.data.data[0]);

          //var imagenPortada = res.data.data[0].imagenCover == null ? '../../../assets/img/cursosv2/1.png' : res.data.data[0].imagenCover;
          var imagenPortada = res.data.data[0].imagen == null ? '../../../assets/img/cursosv2/1.png' : res.data.data[0].imagen;

          this.totalcursos.push([
            imagenPortada,
            res.data.data[0].nombreCurso,
            Math.round( (res.data.data.reduce(this.reducerSum, 0) * 100) / res.data.data.length ) - 1,
            res.data.data[0].idCurso,
          ]);
          //console.log(this.totalcursos);
          if (this.totalcursos.length != null) {
            for (var j = 0; j < this.cursosarray.length; j++) {
              if (this.totalcursos[j][2] > -1) {
                if (this.cursosPorcentaje.includes(this.totalcursos[j])) {
                  //console.log('CURSOS_PORCENTAJE', this.cursosPorcentaje);
                } else {
                  this.cursosPorcentaje.push(this.totalcursos[j]);
                }
              }
            } //fin for2
          } // fin if 1
        })
        .catch((err) => {
          //console.log(err.response);
        })
        .finally(() => {
          //console.log('RES', this.totalcursos);
          //console.log(payload);
        });
    } //fin for 1
    //console.log('avanze:%%%', this.cursosPorcentaje);
  }

  //Porcertante de cursos
  reducerSum(accumulator: any, currentValue: any) {
    if (currentValue?.completoVista === 'SI') {
      return accumulator + 1;
    }
    return accumulator;
  }

  entraCurso(id: any) {
    let token = localStorage.getItem('token');
    if (token) {
      this.router.navigate([`usuario/cursos-view/${token}/${id}`]);
      this.scrollToTop();
    } else if (token == null) {
      //this.modalLogin = true;
      //console.log(this.modalLogin);
      this.switchService.$modalLogin.emit(true);
    }
  }

  listarUsuario() {
    let token = localStorage.getItem('token') || null;
    this.usuarioService.getUser(token).subscribe((resp: any) => {
      localStorage.setItem('nombres', resp.data.nombre);
      this.usuarioService.emitirPerfil.emit();
      //console.log( resp.data.nombre + resp.data.apellidos + resp.data.email + resp.data.pass_word );

      this.nombre = resp.data.nombre;
      this.apellido = resp.data.apellidos;
      this.profesion = resp.data.ciudad;
      this.email = resp.data.email;
      this.celular = resp.data.celular;
      this.telefono = resp.data.celular;

      this.editUsuario = {
        nombre: this.nombre,
        apellidos: this.apellido,
        ciudad: this.profesion,
        email: this.email,
        celular: this.celular,
        telefono: this.telefono,
      };

      if (!this.celular || this.celular.trim() === '') {
        Swal.fire({
          title: '¡Completa tu perfil!',
          text: 'Para brindarte una mejor experiencia, por favor completa tu número de celular en tu perfil.',
          icon: 'info',
          confirmButtonText: 'Entendido',
          confirmButtonColor: '#3085d6',
          background: '#f8f9fa', // Color de fondo suave
          color: '#333', // Color de texto
          padding: '1.5rem',
          backdrop: true,
        });
      }

    });
  }

  divHeight = '100%';

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.divHeight = '100%';
  }

  passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?!.*\s)(?=.*?[0-9])(?=.*?[#?!@$%^&*-]){8,}/;

  editarPerfil = new FormGroup({
    nombre: new FormControl(this.editUsuario.nombre, [
      Validators.required,
      Validators.pattern('[a-zA-Z ]*')
    ]),
    apellido: new FormControl(this.editUsuario.apellidos, [
      Validators.required,
      Validators.pattern('[a-zA-Z ]*')
    ]),
    correo: new FormControl(this.editUsuario.email),
    profesion: new FormControl(this.editUsuario.ciudad),
    celular: new FormControl(this.editUsuario.celular, [
      Validators.pattern('[0-9]{9}')
    ]),
    contrasenia: new FormControl(this.editUsuario.password, [
      Validators.pattern(this.passwordRegex)
    ])
  });

  get Name(): FormControl {
    return this.editarPerfil.get("nombre") as FormControl;
  }

  get Apellido(): FormControl {
    return this.editarPerfil.get("apellido") as FormControl;
  }

  get Celular(): FormControl {
    return this.editarPerfil.get("celular") as FormControl;
  }

  get Password(): FormControl {
    return this.editarPerfil.get("contrasenia") as FormControl;
  }

  editar() {
    if(this.editarPerfil.valid) {
      this.usuarioService.updateUser(this.editUsuario).subscribe((resp: any) => {
        Swal.fire('Editado Correctamente', '', 'success');
        //console.log(resp);
        this.paso = 1;
        console.log('USUARIO EDITADO1');
        this.listarUsuario();
      });
      console.log('USUARIO EDITADO2');
    } else {
      Swal.fire('Complete los Datos', '', 'error');
    }
  }

  listCurso() {
    this.cursos.getCursosRecomendado().subscribe((resp: any) => {
      this.cursoRecomendado = resp.data;
    });
    //console.log('LISTA CURSO');
  }

  listCertificados() {
    this.cursos.getCertificados().subscribe((resp: any) => {
      this.certificados = resp.data;
    });
    //console.log('LISTA CURSO');
  }

  irEditar() {
    this.paso = 2;
  }

  scrollToTop() {
    (function smoothscroll() {
      var currentScroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (currentScroll > 0) {
        window.requestAnimationFrame(smoothscroll);
        window.scrollTo(0, currentScroll - currentScroll / 5);
      }
    })();
  }
}
