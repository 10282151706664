<main class="grid grid-cols-2 md:grid-cols-[318px_auto] md:grid-rows-[122px_auto] min-h-screen" style="background: #FF0000;">
	<!-- Header -->
        <header class="h-[135px] grid place-items-center col-span-full md:border-b md:border-white md:h-auto md:col-span-1 header-pre-home">
            <div class="logo-container">
                <div class="logo">
                    <img src="../../../assets/img/unacem-cantera.png" style="width: 150px; height: auto;" alt="Unacem Cantera Logo"/>
                </div>
            </div>
        </header>
	<!-- Sections -->
        <div class="col-span-full flex flex-col sm:flex-row md:col-start-2 md:col-end-2 md:h-screen">
            <section class="prehome__section sm:mr-[6px] relative flex-1 max-h-[866px] h-[120vw] flex flex-col justify-end md:max-h-screen md:h-full">
                <div routerLink="/constructor" class="pointer z-10 mt-[100px] py-[25px] px-4 text-white sm:py-16 sm:px-5 sm:mt-0 md:py-[50px] md:mx-[33px]">
                    <a class="mb-[10px] inline-block">
                        <img src="../../../../assets/icons/arrow-right.png" alt="arrow-right">
                    </a>
                    <h3 class="text-4xl mb-5">Constructor</h3>
                    <p class="text-[18px] py-5 px-4 border border-white rounded-[10px]">Maestro, capacítate profesionalmente de manera gratuita y mejora tus
                        aptitudes para una mejor ejecución de tus obras.</p>
                </div>
            </section>
            <section class="prehome__section sm:ml-[6px] relative flex-1 max-h-[866px] h-[120vw] flex flex-col justify-end md:max-h-screen md:h-full">
                <a href="https://unacemcantera.com.pe/empresas/" class="pointer z-10 mt-[100px] py-[25px] px-4 text-white sm:py-16 sm:px-5 sm:mt-0 md:py-[50px] md:mx-[33px]">
                    <div>
                        <a class="mb-[10px] inline-block">
                            <img src="../../../../assets/icons/arrow-right.png" alt="arrow-right">
                        </a>
                        <h3 class="text-4xl mb-5">Empresario</h3>
                        <p class="text-[18px] py-5 px-4 border border-white rounded-[10px]">Si eres parte de una empresa constructora, capacítate sobre
                            tendencias del uso del cemento y el concreto.
                        </p>
                    </div>
                </a>
            </section>
        </div>
	<!-- Mobile Fotter - Desktop Sidebar -->
        <section class="col-span-full text-white flex py-[25px] px-4 flex-row sm:py-[56px] sm:px-[46px] md:p-[0px_23px_50px_36px] md:flex-col md:col-span-1 md:justify-between">
            <div class="hidden md:block"></div>
            <div class="pr-[62px] md:pr-0">
                <h2 class="text-[26px] mb-[15px] sm:text-[40px] md:text-[36px] leading-10 font-medium">De la Cantera salen los mejores</h2>
                <p class="pb-[30px] relative before:content-[''] before:h-[2px] before:w-[62px] before:bg-white before:absolute before:bottom-0">
                    Plataforma virtual de capacitaciones profesionales gratuitas que tiene como
                    propósito formar mejores constructores en el país.
                </p>
            </div>
            <!-- <img src="../../../../assets/svg/wsp_tel.svg" alt="" class="wsp_tel"/>
            <img src="../../../../assets/svg/wsp_border.svg" alt="" class="wsp_border"/> -->
<!--             <a href="#" class="self-end md:self-start">
                    <div class="wsp_border">
                        <div class="wsp_tel"></div>
                    </div>
            </a> -->
            <div class=""></div>
        </section>
    </main>
