import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IRegistro, IUsuarioLogin } from '@app/models/IUsuario';
import { SocialUser } from '@abacritt/angularx-social-login';
import { catchError, map, Observable, of, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  httpHeader = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    }),
  };

  constructor(private router: Router, private http: HttpClient) {}

  signIn(usuario: IUsuarioLogin) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    let body = {
      username : usuario.username,
      password : usuario.password
    }
    return this.http.post<any>(
      `${environment.api_url}user/login_user`,
      body,
      { headers: headers },
    );
  }

   // Iniciar sesión con Google enviando el token
  loginWithGoogle(email: string, googleId: string): Observable<any> {
    return this.http.post(`${environment.api_url}user/google-login`,
      { email: email, google_id: googleId },
      this.httpHeader,
    );
  }

  registerWithGoogle(socialUser: SocialUser): Observable<any> {
    let body = {
      nombres: socialUser.firstName,
      apellidos: socialUser.lastName,
      email: socialUser.email,
      google_id: socialUser.id,
    };
    return this.http.post(`${environment.api_url}user/google-register`,  body, this.httpHeader );
  }

  // Vincular la cuenta con Google
  vincularCuentaGoogle(userId: number, googleId: string): Observable<any> {
    return this.http.post(`${environment.api_url}user/vincular-google`,
      { user_id: userId, google_id: googleId },
      this.httpHeader,
    );
  }

  registrar(usuario: IRegistro) {
    const params: any = usuario;
    let body = {
      nombres: usuario.nombres,
      apellidos: usuario.apellidos,
      email: usuario.email,
      password: usuario.password,
    };
    return this.http.post<IRegistro>( `${environment.api_url}user/new_member`, body, this.httpHeader );
  }

  recuperarContrasena(email: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');

    const data = new FormData();
    data.append('email', email);
    return this.http.post<any>(
      environment.api_url + `user/recovery_password_user`,
      data
    );
  }

  private timer: any;
  public startTimer(): void {
    console.log('el timer ah iniciado');
    this.timer = setInterval(() => {
      console.log('XXXhaber1');

      this.validarToken().subscribe(
        (isValid: any) => {
          console.log('XXXhaber2', isValid.success);
          if (!isValid.success) {
            alert('El token ha expirado');
            this.logout();
          } else {
            console.log('aun no termina');
          }
        },
        (error) => {
          console.error('Error al validar token:', error);
        }
      );
    }, 300000); // Verificar cada 1 minuto
  }

  public stopTimer(): void {
    clearInterval(this.timer);
  }

  validarToken(): Observable<boolean> {
    const token = localStorage.getItem('token');
    console.log('este es el token mano', token);
    if (!token) {
      console.log('falso');
      return of(false);
    }
    console.log('Servicio');
    return this.http.post<boolean>(
      `${environment.api_url}user/get_perfil?id=${token}`,
      this.httpHeader
    );
  }

  logout() {
    localStorage.clear();
    this.router.navigate([`/constructor`]);
  }

  //valid key
  validKey( key: string ) {
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const data = new FormData();
    data.append('key', key );
    return this.http.post<any>(
      environment.api_url + `user/validate_key_user`,
      data
    );
  }

  changePassword( user_id: string , password: string){
    let headers = new HttpHeaders();
    headers = headers.set('Content-type', 'application/json');
    const data = new FormData();
    data.append('user_id', user_id );
    data.append('password', password );
    return this.http.post<any>(
      environment.api_url + `user/change_passord_by_id`,
      data
    );
  }
}
