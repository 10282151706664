import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-view-element',
  templateUrl: './view-element.component.html',
  styleUrls: ['./view-element.component.scss']
})
export class ViewElementComponent implements OnInit {

  pdf: any;
  type: any;

  constructor(
    private router: Router,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) {


  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.type = params.get('pdfid');

      if( this.route.snapshot.data['pdf'] == 'manual-de-construccion' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/manual-de-construccion-casas');
        }, 100);
      }
      if( this.route.snapshot.data['pdf'] == 'manual-de-construccion-casas' ){
        this.titleService.setTitle('UNACEM CANTERA - MANUAL DE CONSTRUCCIÓN');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/Manual.pdf');
      }

      if( this.route.snapshot.data['pdf'] == 'tabla-de-equivalencias' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/tabla-equivalencias-construccion');
        }, 100);
      }
      if( this.route.snapshot.data['pdf'] == 'tabla-equivalencias-construccion' ){
        this.titleService.setTitle('UNACEM CANTERA - TABLA DE EQUIVALENCIAS & DOSIFICACIONES');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/tabla.pdf');
      }

      if( this.route.snapshot.data['pdf']  == 'manual-de-manejo' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/manual-de-manejo-construccion');
        }, 100);
      }
      if( this.route.snapshot.data['pdf']  == 'manual-de-manejo-construccion' ){
        this.titleService.setTitle('UNACEM CANTERA - MANEJO,TRANSPORTE Y ALMACENAMIENTO DEL CEMENTO');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/manejo2.pdf');
      }

      if( this.route.snapshot.data['pdf'] == 'curso-evolucion-tendencias' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/curso-tendencias-construccion-peru');
        }, 100);
      }
      if( this.route.snapshot.data['pdf'] == 'curso-tendencias-construccion-peru' ){
        this.titleService.setTitle('UNACEM CANTERA - La evolución de la construcción y tendencias tecnológicas en la actualidad');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/CURSO_EVOLUCION_TENDENCIAS_TECNOLOGICAS.pdf');
      }
      //planos
      if( this.type == 'casas-de-1-piso' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/planos/plano-casas-1-piso');
        }, 100);
      }
      if( this.type == 'plano-casas-1-piso' ){
        this.titleService.setTitle('UNACEM CANTERA - Modelo Casa Tipo 01');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/CASA_TIPO1.pdf');
      }

      if( this.type == 'casas-de-2-piso' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/planos/plano-casas-2-piso');
        }, 100);
      }
      if( this.type == 'plano-casas-2-piso' ){
        this.titleService.setTitle('UNACEM CANTERA - Modelo Casa Tipo 02');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/CASA_TIPO2.pdf');
      }

      if( this.type == 'casas-de-3-piso' ){
        setTimeout(() => {
          this.router.navigateByUrl('/constructor/herramientas/planos/plano-casas-3-piso');
        }, 100);
      }
      if( this.type == 'plano-casas-3-piso' ){
        this.titleService.setTitle('UNACEM CANTERA - Modelo Casa Tipo 03');
        this.pdf = this.sanitizer.bypassSecurityTrustResourceUrl('https://unacemcantera.com.pe/files/CASA_TIPO3.pdf');
      }

      if( !this.pdf ){
        this.titleService.setTitle('UNACEM CANTERA - 404');
        this.router.navigateByUrl('404', { skipLocationChange: true });
      }
    });
  }

}
