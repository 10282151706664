<footer *ngIf="mostrar" class="footer backgroud pt-[117px] pb-[139px] text-center flex flex-col md:pt-[24px] md:pb-[36px] md:pl-[50px] md:pr-[50px]">
    <!-- First Row: Containers one, two, and three -->
    <div class="flex flex-col md:flex-row md:items-center md:justify-between w-full">
        <div class="footer__container one flex-1">
            <a href="https://unacem.pe/" target="_blank">
              <div class="logo-container">
                <div class="logo">
                    <img src="../../../assets/img/unacem-cantera.png" style="width: 150px; height: auto;" alt="Unacem Cantera Logo"/>
                </div>
              </div>
            </a>
            <p class="text-white text-[24px] leading-[160%] md:text-[14px] md:w-[170px]">© 2020 UNACEM</p>
        </div>
        <div class="footer__container two flex-1">
            <a href="https://unacem.pe/" target="_blank">
                <p class="text-white pt-[20px] pb-[20px] text-[30px] leading-[160%] md:text-[20px]">UNACEM PERÚ S.A</p>
            </a>
            <a class="py-5 text-white bg-black hover:bg-black focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 dark:bg-black dark:hover:bg-black focus:outline-none dark:focus:ring-black h-8" href="https://unacem.pe/comercializacion/productos/" target="_blank">Conoce Nuestros Cementos</a>
        </div>
        <div class="footer__container three pb-[45px] text-[24px] flex-1 md:text-[14px] md:text-right relative">
            <div style="padding-top: 15px;">
                <p class="text-white leading-[160%]">Av Atocongo 2440</p>
                <p class="text-white leading-[160%]">Villa Maria del Triunfo. Lima, Perú</p>
                <p class="text-white leading-[160%]"><a href="tel:908828092" target="_blank" rel="noopener noreferrer">T (511) 908 828 092</a></p>
            </div>
        </div>
    </div>
    <!-- Second Row: Container four -->
    <div class="footer__container four pt-[45px] w-full flex flex-row justify-center items-center mt-5 md:mt-3">
        <div class="policies text-white md:text-[14px] flex flex-col items-center mx-5">
            <a class="mr-5" href="https://cementosol.com.pe/img/pdf/UNACEM_Politica_de_Privacidad.pdf" target="_blank">Política de Privacidad</a>
        </div>
         <div class="policies text-white md:text-[14px] flex flex-col items-center mx-5">
            <a class="mr-5" href="/assets/pdf/politica-cookies-unacem.pdf" target="_blank">Política de Cookies</a>
        </div>

        <div class="policies text-white md:text-[14px] flex flex-col items-center mx-5">
            <a class="mr-5" href="/assets/pdf/preguntas-frecuentes.pdf" target="_blank">Preguntas Frecuentes</a>
        </div>

        <div class="reclamations text-white md:text-[14px] text-center flex flex-col items-center mx-5">
            <a href="/constructor/libro-de-reclamaciones" target="_blank">
                <img src="../../../assets/img/libro-de-reclamaciones-1.png" class="w-24 h-auto max-w-full"/>
            </a>
        </div>

    </div>
</footer>
