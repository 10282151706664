import { Component, OnInit } from '@angular/core';
import { ISliderItem } from '@app/components/slider-home/ISlider-item.metadata';
import { SLIDER_DATA_ITEMS } from '@app/components/slider-home/slider.cons';
import { SwitchService } from '@app/services/switch.service';
import { StatsService } from '@app/services/stats.service';
import { Title, Meta } from '@angular/platform-browser';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';

@Component({
  selector: 'app-constructor',
  templateUrl: './constructor.component.html',
  styleUrls: ['./constructor.component.scss']
})
export class ConstructorComponent implements OnInit {

  stats: any = {};

  mostrar: boolean = true;

  public sliderData: ISliderItem[] = SLIDER_DATA_ITEMS;
  constructor(
    private switchService: SwitchService,
    private statsService: StatsService,
    private http: HttpClient,
    private titleService: Title,
    private metaService: Meta
  ) { }

  ngOnInit(): void {
    this.loadStats();
    this.getMetaTagsData();
    this.switchService.$nav.subscribe((valor)=>{this.mostrar = valor})
  }

  //metadatos de seo
  getMetaTagsData() {
    this.http.get<any>("/assets/metatags/constructor/constructor.json").subscribe(res => {
      this.setPageTile(res.title)
      if(res.tags){
        this.setMetaTags(res.tags);
      }
    })
  }

  loadStats() {
    this.statsService.getStats().subscribe(data => {
      this.stats = data;
    });
  }

  // set meta data
  setMetaTags(tags: any ) {
    this.metaService.addTags(tags)
  }

  // set page title
  setPageTile(title: string) {
    this.titleService.setTitle(title);
  }

}
