import {
  Component,
  HostListener,
  OnInit,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IUsuarioLogin } from '@app/models/IUsuario';
import { SocialAuthService, GoogleLoginProvider, SocialUser } from '@abacritt/angularx-social-login';
import { AuthService } from '@app/services/auth.service';
import { SwitchService } from '@app/services/switch.service';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit, OnDestroy {

  formLogin!: UntypedFormGroup;
  formLoginRecuperar!: UntypedFormGroup;
  siteKey: string = environment.recaptcha_pk; //codigo para reCaptcha
  private correoPattern: any =
    /^[a-zA-Z0-9_\.-]{3,40}@[a-z]{2,20}\.[a-z]{2,10}(\.[a-z]{2,10}){0,2}$/; // patrón correo
  modalLogin: boolean = true;
  modalRegistro: boolean = true;
  cerrar: boolean = true;
  recuperar: boolean = true;

  loginUsuario: IUsuarioLogin = {
    username: '',
    password: '',
  };

  recuperarForm: any = {
    username: '',
  };

  socialUser: SocialUser | null = null;
  isLoggedin: boolean = false;
  authSubscription: Subscription | null = null;

  constructor(
    private modalSS: SwitchService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private socialAuthService: SocialAuthService
  ) {}

  ngOnInit(): void {
    this.formLogin = this.fb.group({
      email: [
        null,
        [Validators.required, Validators.pattern(this.correoPattern)],
      ],
      password: [null, [Validators.required]],
      recaptcha: [null, Validators.required],
    });

    this.formLoginRecuperar = this.fb.group({
      email: [
        null,
        [Validators.required, Validators.pattern(this.correoPattern)],
      ],
    });

    this.authSubscription = this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;
      this.isLoggedin = user != null;

      if (!user) return;

      this.authService.loginWithGoogle(this.socialUser.email, this.socialUser.id).subscribe({
        next: (resp) => {
          if (resp.usuario_no_registrado) {
            Swal.fire('Usuario no registrado', 'Por favor, regístrese antes de iniciar sesión.', 'warning');
          } else if (resp.vincular) {
            Swal.fire({
              title: 'Vincular cuenta',
              text: resp.message,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonText: 'Sí, vincular',
              cancelButtonText: 'Cancelar',
            }).then((result) => {
              if (result.isConfirmed) {
                this.authService.vincularCuentaGoogle(resp.user.id, resp.googleId).subscribe({
                  next: () => {
                    Swal.fire('Cuenta vinculada con éxito', 'Ahora puede iniciar sesión con Google', 'success').then(() => {
                      window.location.reload();
                    });
                  },
                  error: (err) => {
                    Swal.fire('Error', err.status === 400 ? 'No se pudo vincular la cuenta. Inténtalo de nuevo.' : 'Ocurrió un problema inesperado.', 'error');
                  }
                });
              }
            });
          } else {
            localStorage.setItem('token', resp.token);
            Swal.fire('Inicio de sesión exitoso', '', 'success');
            this.router.navigate(['/usuario/perfil']);
          }

          this.clearAuthState();
        },
        error: (error) => {
          Swal.fire('Error al iniciar sesión', error.error.message, 'error');

          this.clearAuthState();
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  private clearAuthState() {
    this.socialUser = null;
    this.isLoggedin = false;
    this.authSubscription?.unsubscribe();
    this.authSubscription = null;
    this.socialAuthService.signOut();
  }

  onLogin() {
    if (this.formLogin.invalid) {
      Swal.fire('Complete los Datos', '', 'error');
    } else {
      this.authService.signIn(this.loginUsuario).subscribe(
        (resp) => {
          this.router.navigate(['/usuario/perfil']);
          //console.log(resp.token);
          Swal.fire('Logueado Correctamente', '', 'success');
          localStorage.setItem('token', resp.token);
        },
        (error) => {
          Swal.fire(error.error.error, '', 'error');
          // console.log(error);
        }
      );
      console.log('login', this.loginUsuario);
    }
  }

  irRecuperar() {
    this.recuperar = false;
  }
  closeLogin() {
    if (this.cerrar == true) {
      this.modalSS.$modalLogin.emit(false);
    }
  }
  closeLoginMovile() {
    this.modalSS.$modalLogin.emit(false);
  }

  irRegistro() {
    this.modalSS.$modalLogin.emit(false);
    this.modalSS.$modalRegistro.emit(true);
  }

  irLogin() {
    this.recuperar = true;
  }

  recuperarContrasena() {
    if (this.formLoginRecuperar.invalid) {
      Swal.fire('Complete los Datos', '', 'error');
    } else {
      this.authService
        .recuperarContrasena(this.recuperarForm.username)
        .subscribe(
          (resp) => {
            this.router.navigate(['/usuario/perfil']);
            //Swal.fire('Contraseña enviado a su correo', '', 'success');
            Swal.fire('Se ha enviado un correo con las indicaciones para recuperar tu contraseña', '', 'success');

          },
          (error) => {
            Swal.fire('Correo invalido', '', 'error');
            console.log(error);
          }
        );
      //console.log("login",this.loginUsuario);
    }
  }

  noCerrar() {
    this.cerrar = false;
  }

  noCerrar2() {
    this.cerrar = true;
  }
}
